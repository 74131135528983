@import '~darrius/src/styles/mixins/media';

.category-page {
  > *:nth-child(even) {
    background-color: var(--color-neutral-light-200);
  }

  :global {
    .best-pros,
    .cta-pro,
    .budgets,
    .pro-list,
    .average-prices,
    .similar-requests,
    .faq {
      @include media(normal) {
        padding: var(--size-spacing-stack-1400) 0;
      }

      padding: var(--size-spacing-stack-900) 0;
    }
  }

  .main-section {
    :global {
      .breadcrumb {
        @include media(normal) {
          margin-bottom: var(--size-spacing-stack-1000);
        }

        margin-bottom: var(--size-spacing-stack-700);
      }
    }

    // Necessário para a responsividade em desktop, fazendo o título ficar próximo dos itens abaixo
    &__title-area {
      @include media(normal) {
        margin-bottom: -12rem;
      }

      @include media(large) {
        margin-bottom: -14rem;
      }
    }

    @media (max-width: 996px) {
      &__title {
        margin-bottom: 0;

        h1 {
          margin: 0;
          font-size: var(--size-font-2xl);
        }
      }

      &__subtitle {
        line-height: var(--font-line-height-normal);
        color: var(--color-neutral-dark-400);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);
      }

      &__image {
        display: flex;

        img {
          object-fit: contain;
        }
      }
    }
  }

  .form-and-ratings {
    @include media(normal) {
      background: linear-gradient(
        90deg,
        var(--color-neutral-light-100) 0%,
        var(--color-neutral-light-100) 50%,
        var(--color-neutral-light-200) 51%,
        var(--color-neutral-light-200) 100%
      );
    }

    padding: 0;

    :global {
      .container {
        padding: 0;
      }
    }

    .form-area,
    .ratings {
      @include media(normal) {
        padding: var(--size-spacing-stack-1200) var(--size-spacing-inline-700);
      }

      padding: var(--size-spacing-stack-900) var(--size-spacing-inline-300);
    }

    .form-area {
      @include media(normal) {
        padding: var(--size-spacing-stack-1200) var(--size-spacing-inline-700);
      }

      background-color: var(--color-brand-500);
      padding: var(--size-spacing-stack-300) var(--size-spacing-inline-300);
    }
  }

  @media (max-width: 996px) {
    :global {
      .row.slider-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;

        // Quando houver mais de 1, seleciona todos os filhos diretos.
        > *:not(:only-child) {
          margin-right: var(--size-spacing-inline-300);
          max-width: 85%;
        }
      }
    }
  }
}

.max-width-full {
  display: block;
  max-width: 100%;
}

@media (max-width: 768px) {
  .max-width-full {
    height: auto;
  }
}

.profissional-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
